.image-container {

    width: 100%;
    margin-top: 50px;
    padding: 0 10px; 
    max-width: 100%;
}
.image-container img {
    margin-top: 20px;
    max-width: 90%; /* 이미지가 컨테이너를 벗어나지 않도록 설정 */
    height: auto; /* 이미지 비율 유지 */
    margin-bottom: 50px;
}


.image-container p {
    padding-left: 10px;
    padding-top: 10px;
}

@media screen and (min-width:481px) and (max-width: 600px) {
    .image-container {
        width: 100%;
        margin-top: 20px;
    }
    .image-container img {
        margin-top: 20px;
        max-width: 50%;
    
    }
    

}

h1
   {
    font-size: 25px;
  }

h2{
    font-size: 20px;
}

h3{
    font-size: 18px;
}
h4{
    font-size: 16px;
}
h5{
    font-size: 14px;
}

