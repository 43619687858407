@media screen and (min-width: 800px) and (max-width: 1440px) {

  #text-line{
  display: block;

  }
  .img-line{
      width: 50%;
      height: 100%;

  }
  #maintext{
      font-size: 110px;

  }
  #img2{
    margin-left: 0%;
      
  }
  #ttline{
    margin-left: 10%;
  }
  #ttline2{
  display: inline;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .img-line{
      width: 50%;
      height: 100%;
  
  }
  #maintext{
      font-size: 100px;
      
  
  }
}
