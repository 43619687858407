@media screen and (min-width: 900px) and (max-width: 1440px) {
	#footer-text1{
		display: flex;
		font-size: 20px;
	
	}
	#footer-text2{
	
		display: flex;
		font-size: 20px;
	}
	#t-footer{
		height: 160px;
	}

}
