@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 100;
  src: url('../../Font/SCDream1.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 200;
  src: url('../../Font/SCDream2.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 300;
  src: url('../../Font/SCDream3.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 400;
  src: url('../../Font/SCDream4.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 500;
  src: url('../../Font/SCDream5.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 600;
  src: url('../../Font/SCDream6.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 700;
  src: url('../../Font/SCDream7.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 800;
  src: url('../../Font/SCDream8.otf') format('truetype');
}

@font-face {
  font-family: 'SCDream';
  font-style: normal;
  font-weight: 900;
  src: url('../../Font/SCDream9.otf') format('truetype');
}

#font {
  font-family: 'SCDream';
  font-weight: 800; /* SCDream8 폰트 적용 */
  color: #424242;
}

#font1 {
  font-family: 'SCDream';
  font-weight: 800; /* SCDream8 폰트 적용 */
  color: #0085FF;
}
