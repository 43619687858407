@media screen and (min-width: 800px) and (max-width: 1440px) {
    #textline1{
       font-size: 20px;

    }
    #textline2{
        right: 0;
    }
    #textline3{
        left: 0;
    }
    #twosection{
        margin-left: 2%;
    }
    #twosection1{
        width: 360px;
    }
    #twosection2{
        width: 600px;
        height: 300px;
        padding-left: 10px;

    }
    #twosection0{
        height: 300px;
        width: 1200px;
    }
    #twosection3{
        width: 900px;
        height: 300px;
        padding-left: 10px;

    }
    #twosection5
    {
    padding-top: 10px;
     font-size: 20px;
    }

    #twosection6
    {
        height: 200px;
    }
    #twosection7{
      
        margin-left: 20px;
    }

}