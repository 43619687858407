
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Pretendard-Regular';
  font-style: normal;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: 'Pretendard-Regular';
  font-style: normal;
  /* font-weight: 400; */
	/* src:url("./Font/Pretendard-Black.ttf")  format('truetype'), */
		/* url("./Font/Pretendard-Bold.ttf")  format('truetype'), */
		/* url("./Font/Pretendard-Bold.ttf")  format("truetype"), */
		/* url("./Font/Pretendard-ExtraBold.ttf")  format("truetype"), */
		/* url("./Font/Pretendard-ExtraLight.ttf")  format("truetype"), */
		/* url("./Font/Pretendard-Light.ttf")  format("truetype"), */
		/* url("./Font/Pretendard-Medium.ttf")  format("truetype"),  */
		/* url("./Font/Pretendard-SemiBold.ttf")  format("truetype"),  */
		/* url("./Font/Pretendard-Thin.ttf")  format("truetype"), */
     
  
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
#t-footer{
 height: 300px;
}
#footer-text1{
  display: block;
}
#footer-text2{
  display: block;
}
}